import React, { useState, useEffect } from 'react';
import {
    Avatar, Badge, Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Divider,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableRow
} from '@mui/material';
import {useTheme} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {AxiosGet} from "../../axios/AxiosGet";
import {
    CustomContentForLoadAndError, CustomContentForLoadAndErrorWithContent
} from "../../components/CustomContentForLoadAndError";
import ContentLoader from "react-content-loader";
import {useSnackbar} from "notistack";

const testData = [
    {
        id: 1,
        processId: "test_process",
        processName: "Process 1",
        title: "Загрузка файла",
        createdBy: "test_user",
        bank: "test_bank",
        pointId: 1,
        pointName: "Обработка транзакций",
        startTime: "2023-04-25 10:00:00",
        endTime: "",
        status: "STARTED",
        type: "SIMPLE",
        countPointsInCycle: "",
        currentStepInCycle: "",
        message: "Начинаем подневную обработку тразакций",
        criticalError: "",
        warningMessage: ""
    },
    {
        id: 2,
        processId: "test_process",
        processName: "Process 1",
        title: "Загрузка файла",
        createdBy: "test_user",
        bank: "test_bank",
        pointId: 1,
        pointName: "Обработка транзакций",
        startTime: "2023-04-25 10:00:00",
        endTime: "2023-04-25 12:00:00",
        status: "FINISHED",
        type: "SIMPLE",
        countPointsInCycle: "",
        currentStepInCycle: "",
        message: "Начинаем подневную обработку тразакций",
        criticalError: "",
        warningMessage: ""
    },
    {
        id: 3,
        processId: "test_process_1",
        processName: "Process 1",
        title: "Загрузка файла",
        createdBy: "test_user",
        bank: "test_bank",
        pointId: 2,
        pointName: "Обработка транзакций",
        startTime: "2023-04-25 10:00:00",
        endTime: "",
        status: "STARTED",
        type: "SIMPLE",
        countPointsInCycle: "",
        currentStepInCycle: "",
        message: "Начинаем подневную обработку тразакций",
        criticalError: "",
        warningMessage: ""
    },
    {
        id: 3,
        processId: "test_process_1",
        processName: "Process 1",
        title: "Загрузка файла",
        createdBy: "test_user",
        bank: "test_bank",
        pointId: 3,
        pointName: "Обработка транзакций",
        startTime: "2023-04-25 10:00:00",
        endTime: "",
        status: "STARTED",
        type: "SIMPLE",
        countPointsInCycle: "",
        currentStepInCycle: "",
        message: "Начинаем подневную обработку тразакций",
        criticalError: "",
        warningMessage: ""
    },
    {
        id: 3,
        processId: "test_process_1",
        processName: "Process 1",
        title: "Загрузка файла",
        createdBy: "test_user",
        bank: "test_bank",
        pointId: 4,
        pointName: "Обработка транзакций",
        startTime: "2023-04-25 10:00:00",
        endTime: "",
        status: "WARNING",
        type: "SIMPLE",
        countPointsInCycle: "",
        currentStepInCycle: "",
        message: "Начинаем подневную обработку тразакций",
        criticalError: "",
        warningMessage: ""
    },
    {
        id: 3,
        processId: "test_process_1",
        processName: "Process 1",
        title: "Загрузка файла",
        createdBy: "test_user",
        bank: "test_bank",
        pointId: 5,
        pointName: "Обработка транзакций",
        startTime: "2023-04-25 10:00:00",
        endTime: "",
        status: "ERROR",
        type: "SIMPLE",
        countPointsInCycle: "",
        currentStepInCycle: "",
        message: "Начинаем подневную обработку тразакций",
        criticalError: "",
        warningMessage: ""
    },
    {
        id: 3,
        processId: "test_process_1",
        processName: "Process 1",
        title: "Загрузка файла",
        createdBy: "test_user",
        bank: "test_bank",
        pointId: 6,
        pointName: "Обработка транзакций",
        startTime: "2023-04-25 10:00:00",
        endTime: "",
        status: "STARTED",
        type: "SIMPLE",
        countPointsInCycle: "",
        currentStepInCycle: "",
        message: "Начинаем подневную обработку тразакций",
        criticalError: "",
        warningMessage: ""
    },
    {
        id: 3,
        processId: "test_process_1",
        processName: "Process 1",
        title: "Загрузка файла",
        createdBy: "test_user",
        bank: "test_bank",
        pointId: 7,
        pointName: "Обработка транзакций",
        startTime: "2023-04-25 10:00:00",
        endTime: "",
        status: "STARTED",
        type: "SIMPLE",
        countPointsInCycle: "",
        currentStepInCycle: "",
        message: "Начинаем подневную обработку тразакций",
        criticalError: "",
        warningMessage: ""
    },
    {
        id: 3,
        processId: "test_process_1",
        processName: "Process 1",
        title: "Загрузка файла",
        createdBy: "test_user",
        bank: "test_bank",
        pointId: 8,
        pointName: "Обработка транзакций",
        startTime: "2023-04-25 10:00:00",
        endTime: "",
        status: "STARTED",
        type: "SIMPLE",
        countPointsInCycle: "",
        currentStepInCycle: "",
        message: "Начинаем подневную обработку тразакций",
        criticalError: "",
        warningMessage: ""
    },
    {
        id: 3,
        processId: "test_process_1",
        processName: "Process 1",
        title: "Загрузка файла",
        createdBy: "test_user",
        bank: "test_bank",
        pointId: 9,
        pointName: "Обработка транзакций",
        startTime: "2023-04-25 10:00:00",
        endTime: "",
        status: "STARTED",
        type: "SIMPLE",
        countPointsInCycle: "",
        currentStepInCycle: "",
        message: "Начинаем подневную обработку тразакций",
        criticalError: "",
        warningMessage: ""
    },
    {
        id: 3,
        processId: "test_process_1",
        processName: "Process 1",
        title: "Загрузка файла",
        createdBy: "test_user",
        bank: "test_bank",
        pointId: 10,
        pointName: "Обработка транзакций",
        startTime: "2023-04-25 10:00:00",
        endTime: "",
        status: "STARTED",
        type: "SIMPLE",
        countPointsInCycle: "",
        currentStepInCycle: "",
        message: "Начинаем подневную обработку тразакций",
        criticalError: "",
        warningMessage: ""
    },
];

function CheckPointGrid({checkpoint, childrenMargin}) {
    const theme = useTheme();
    const statusColors = {
        FINISHED: theme.palette.success.dark,
        STARTED:  theme.palette.info.light,
        WARNING: theme.palette.warning.light,
        ERROR: theme.palette.error.light,
    };
    const childrenMarginInner = childrenMargin;

    return <>
    <Grid key={checkpoint.id} container spacing={1} sx={{width: '100%', m: 'unset'}}>
                <Grid item xs={3} align="center" display="flex" sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    // backgroundColor: statusColors[checkpoint.status],
                    padding: "unset !important",
                    height: "2rem",
                    // borderRight: "0.2rem solid " + statusColors[checkpoint.status],
                    // borderLeft: "2px solid " + statusColors[checkpoint.status]
                }}>
                        <Typography variant="uptitleBold3" color={theme.palette.text.secondary}>
                            {checkpoint.startTime.replace("T", " ")}
                        </Typography>
                </Grid>
                <Grid item xs={9} align="left" sx={{ paddingTop: "0.5rem", pb: '0.5rem' }}>
                    <Box sx={{
                        borderLeft: "0.2rem solid " + statusColors[checkpoint.status], paddingLeft: '0.5rem',
                        marginLeft: childrenMargin + 'rem'
                    }}>
                        <Typography variant="uptitleBold3" color={theme.palette.text.primary}>
                            {checkpoint.message}
                        </Typography>
                    </Box>
                </Grid>

    </Grid>
        {
            checkpoint.children ? checkpoint.children.map((elem) => (
            <CheckPointGrid key={elem.id} checkpoint={elem} childrenMargin={childrenMarginInner + 0.5}/>
            ))
            :
            <></>
        }
        </>;
}

function RowsDataModal({url, title, isOpen, onClose }) {
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const { loaded, data, error } = AxiosGet(url, "GET", null, enqueueSnackbar);


    let res = CustomContentForLoadAndErrorWithContent(loaded,error, LoaderContent(isOpen, onClose, title));
    if(res !== null)
        return res;
    else {
        const groupedData1 = {};

        data.forEach(item => {
            // Проверка на статус WARNING или ERROR
            if (item.status === 'WARNING' || item.status === 'ERROR') {
                groupedData1[item.id] = { ...item, children: [] }; // Добавляем как отдельный элемент
            } else {
                const groupKey = item.pointIdUnique;
                if (!groupedData1[groupKey]) {
                    groupedData1[groupKey] = { ...item, minId: item.id, firstMessage: item.message, children: [] };
                } else {
                    if (groupedData1[groupKey].id < item.id) {
                        groupedData1[groupKey] = { ...item, minId: groupedData1[groupKey].minId, firstMessage: groupedData1[groupKey].firstMessage, children: groupedData1[groupKey].children };
                    }
                    if (groupedData1[groupKey].minId > item.id) {
                        groupedData1[groupKey].minId = item.id;
                    }
                }
            }
        });

        // Установка минимального id и первого сообщения для сгруппированных элементов
        Object.values(groupedData1).forEach(group => {
            if (group.minId) {
                group.id = group.minId;
                delete group.minId; // Удаляем временное свойство minId
            }
            if (group.firstMessage) {
                group.message = group.firstMessage;
                delete group.firstMessage; // Удаляем временное свойство firstMessage
            }
        });

        const originalData = Object.values(groupedData1).filter(item => item.type !== 'PROCESS').sort((a, b) => {
            if (a.id < b.id) {
                return -1;
            } else if (a.id > b.id) {
                return 1;
            } else {
                return 0;
            }
        });


        // Рекурсивная функция для добавления дочерних элементов
        const addChildToParent = (children, allItems) => {
            children.forEach(child => {
                const directChildren = allItems.filter(item =>
                    item.parentPointIdUnique === child.pointIdUnique);
                child.children = directChildren;

                if (directChildren.length > 0) {
                    // Удаление добавленных дочерних элементов из общего массива
                    allItems = allItems.filter(item => !directChildren.includes(item));
                    // Рекурсивное добавление вложенных дочерних элементов
                    addChildToParent(directChildren, allItems);
                }
            });
            return allItems; // Возвращаем оставшиеся элементы
        };

        // Начальная фильтрация для элементов верхнего уровня
        const topLevelItems = originalData.filter(item => !item.parentPointIdUnique);
        let remainingItems = originalData.filter(item => item.parentPointIdUnique);

        // Добавление дочерних элементов к элементам верхнего уровня
        addChildToParent(topLevelItems, remainingItems);

        topLevelItems.sort((a, b) => {
            if (a.id < b.id) {
                return -1;
            } else if (a.id > b.id) {
                return 1;
            } else {
                return 0;
            }
        })



        return (
            <Dialog open={isOpen} onClose={onClose}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent sx={{p: '1rem !important'}}>
                    {loaded ? (
                        topLevelItems.map((elem) => (
                            <CheckPointGrid key={elem.id} checkpoint={elem} childrenMargin={0}/>
                        ))
                    ) : (
                        <></>
                    )}
                </DialogContent>
                {/*<DialogActions>*/}
                {/*    <Button onClick={onClose}>Close</Button>*/}
                {/*</DialogActions>*/}
            </Dialog>
        );
    }
}


export function LoaderContent(isOpen, onClose, title) {
    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent sx={{p: '1rem !important'}}>
                <ContentLoader
                    speed={2}
                    viewBox="0 0 400 160"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb">
                    <rect x="0" y="8" rx="3" ry="3" width="88" height="6" />
                    <rect x="0" y="26" rx="3" ry="3" width="52" height="6" />
                    <rect x="0" y="100" rx="3" ry="3" width="410" height="6" />
                    <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
                    <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
                    {/*<circle cx="21" cy="19" r="20" />*/}
                </ContentLoader>
            </DialogContent>
        </Dialog>
    );
}

export default RowsDataModal;
