import { createSlice } from '@reduxjs/toolkit';

export const billingTypeFilterSlice = createSlice({
    name: 'billingTypeFilter',
    initialState: {
        selectedBillingType: null,
    },
    reducers: {
        setSelectedBillingType: (state, action) => {
            state.selectedBillingType = action.payload;
        },
    },
});


export const { setSelectedBillingType } = billingTypeFilterSlice.actions;
