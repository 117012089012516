import {useSnackbar} from "notistack";
import UseToken from "../../auth/UseToken";
import React, {useEffect, useState} from "react";
import {AxiosPostWithoutState} from "../../axios/AxiosPost";
import {jobServiceApiUrl, processServiceApiUrl} from "../../schema/Environment";
import DashboardCard from "../../components/shared/DashboardCard";
import {Badge, Button, Stack, Tooltip, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import MultiSelectField from "../../components/inputs/MultiSelectField";
import CustomDatePicker from "../../components/inputs/CustomDatePicker";
import {addJobList, addProcessInfoByList} from "./reducers/jobsSlice";
import {useDispatch} from "react-redux";
import {AxiosGetWithoutState} from "../../axios/AxiosGet";
import SelectField from "../../components/inputs/SelectField";
import StringField from "../../components/inputs/StringField";


export const ParamBox = ({ item, handleChange }) => (
    <Box
        sx={{
            width: '100%',
            border: '1px solid #d6d6d6bf',
            borderRadius: '5px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1rem'
        }}
    >
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        {item.required ? (
                            <Tooltip title="Обязательный параметр">
                                <Badge color="error" variant="dot" sx={{ width: 100 }}>
                                    <Typography variant="h6">Название</Typography>
                                </Badge>
                            </Tooltip>
                        ) : (
                            <Typography variant="h6">Название</Typography>
                        )}
                        <Typography variant="body1" color="textSecondary">
                            {item.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h6">Тип</Typography>
                        <Typography variant="body1" color="textSecondary">
                            {translateFileType(item.jobParamType)}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6">Описание</Typography>
                <Typography variant="body1" color="textPrimary">
                    {item.description}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {item.jobParamType === 'VALUE_LIST' ? (
                    <MultiSelectField id={item.id} itemList={item.jobParamValues} handleChange={handleChange} />
                ) :
                    item.jobParamType === 'STRING_FROM_VALUE_LIST' ? (
                        <SelectField id={item.id} itemList={item.jobParamValues} handleChange={handleChange} />
                        )
                    :
                        item.jobParamType === 'STRING' ? (
                                <StringField id={item.id} itemList={item.jobParamValues} handleChange={handleChange} />
                            )
                            :
                    (item.jobParamType === 'DATE' ? (
                        <CustomDatePicker id={item.id} handleChange={handleChange}/>
                    ) :
                    (
                        <></>
                    ))}

            </Grid>
        </Grid>
    </Box>
);

export function JobLauncherBox ({job, url}) {
    const { enqueueSnackbar } = useSnackbar();
    const {currentToken, setToken} = UseToken();
    const dispatch = useDispatch();

    const [jobData, setJobData] = useState({
        id: job.id,
        description: job.description,
        jobParams: job.jobParams
    },[job]);


    useEffect(() => {
        setJobData({
            id: job.id,
            description: job.description,
            jobParams: job.jobParams
        });
    }, [job]);

    if(!job)
        return (<></>);

    function handleParamChange(paramId, newValue) {
        // Создаем новый объект Job с обновленным параметром value
        const updatedJob = {
            ...jobData,
            jobParams: jobData.jobParams.map(param => {
                if (param.id === paramId) {
                    return { ...param, value: newValue };
                }
                return { ...param};
            }),
        };
        // Обновляем состояние компонента с новым объектом Job
        setJobData(updatedJob);
    }

    const sortedValues = jobData.jobParams.sort((a, b) => {
        if (a.id < b.id) {
            return -1;
        } else if (a.id > b.id) {
            return 1;
        } else {
            return 0;
        }
    });

    const handleClick = () => {
        const launchParamMap = {};
        // debugger;
        jobData.jobParams.filter(item => item.value !== null && item.value !== "null" && item.value !== undefined && item.value !== "undefined" ).forEach(obj => {
            launchParamMap[obj.name] = obj.value;
        });

        AxiosPostWithoutState(url,{jobId: jobData.id}, launchParamMap,  currentToken).then(({loaded, error, data}) => {

            if(error){
                enqueueSnackbar(error, {variant: "error"});
            }
            else {
                enqueueSnackbar("Отправлена на выполнение", {variant: "success"});
                dispatch(addJobList(data));

                const processIdListQuery = data.map(job => encodeURIComponent(job.processId)).join(',');

                // Добавляем инвормацию о выполнении задачи
                AxiosGetWithoutState(processServiceApiUrl + "/process/find/byProcessIdList", "GET", { processIds: processIdListQuery }, currentToken).then(({loaded, error, data}) => {

                    if(error){
                        enqueueSnackbar(error, {variant: "error"});
                        console.log("Не удалось получить статусы процессов: \n" + error);
                    }
                    else {
                        dispatch(addProcessInfoByList(data));
                    }
                }).catch(error => {
                    enqueueSnackbar("Не удалось получить статусы процессов", {variant: "error"});
                    console.log("Не удалось получить статусы процессов: \n" + error);
                });
            }
        }).catch(error => {
            enqueueSnackbar("Непредвиденная ошибка при постановке задачи", {variant: "error"});
        });
    }

    return(
        <DashboardCard title="Параметры">
            <Typography variant="body1" color="textPrimary" sx={{ mb: '1rem' }}>
                {jobData?.description}
            </Typography>
            <Grid container spacing={2}>
                {sortedValues.map((item, index) => (
                    <Grid key={index} item xs={12} sm={6} md={4} lg={4}>
                        <ParamBox item={item} handleChange={handleParamChange} />
                    </Grid>
                ))}
            </Grid>
            <Stack spacing={1} direction="row" justifyContent="flex-end">
                <></>
                <Button variant="contained" color="primary" sx={{mt: '1rem'}} onClick={handleClick}>
                    Запустить
                </Button>
            </Stack>
        </DashboardCard>
    )
}

function translateFileType(fileType) {
    const translations = {
        "STRING_FROM_VALUE_LIST": "Значение из списка",
        "STRING": "Строка",
        "VALUE_LIST": "Список значений",
        "DATE": "Дата"
    };

    // Возвращаем перевод для данного типа файла, или сам тип, если перевод не найден
    return translations[fileType] || fileType;
}

export default JobLauncherBox;
