import ContentLoader from "react-content-loader";
import React from "react"
import PageContainer from "../components/container/PageContainer";

export function CustomContentForLoadAndError(loaded, error) {
    if (error !== null) {
        return (
            <></>
        )
    }
    else if (loaded === false){
        return (
            <PageContainer title="Загрузка" description="Загрузка">
                    <ContentLoader
                        speed={2}
                        // width={400}
                        // height={160}
                        viewBox="0 0 400 160"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb">
                        <rect x="0" y="8" rx="3" ry="3" width="88" height="6" />
                        <rect x="0" y="26" rx="3" ry="3" width="52" height="6" />
                        <rect x="0" y="100" rx="3" ry="3" width="410" height="6" />
                        <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
                        <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
                        {/*<circle cx="21" cy="19" r="20" />*/}
                    </ContentLoader>
            </PageContainer>
        )
    }

    return null;
}

export function CustomContentForLoadAndErrorWithContent(loaded, error, loaderContent) {
    if (error !== null) {
        return (
            <></>
        )
    }
    else if (loaded === false){
        return (
            loaderContent
        )
    }

    return null;
}
