
export default function timeUtils() {
    const ONE_SECOND = 1; // одна секунда в миллисекундах
    const ONE_MINUTE = 60 * ONE_SECOND; // одна минута в миллисекундах
    const ONE_HOUR = 60 * ONE_MINUTE; // один час в миллисекундах
    const ONE_DAY = 24 * ONE_HOUR; // один день в миллисекундах

    function formatDuration(duration) {
        const days = Math.floor(duration / ONE_DAY);
        duration -= days * ONE_DAY;

        const hours = Math.floor(duration / ONE_HOUR);
        duration -= hours * ONE_HOUR;

        const minutes = Math.floor(duration / ONE_MINUTE);
        duration -= minutes * ONE_MINUTE;

        const seconds = Math.floor(duration / ONE_SECOND);

        let hoursStr = hours.toString().length === 1 ? "0" +  hours.toString() : hours.toString();
        let minutesStr = minutes.toString().length === 1 ? "0" +  minutes.toString() : minutes.toString();
        let secondsStr = seconds.toString().length === 1 ? "0" +  seconds.toString() : seconds.toString();

        return `${hoursStr}:${minutesStr}:${secondsStr}`;
    }

    return { formatDuration };
}
