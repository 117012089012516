import { createSlice } from '@reduxjs/toolkit';

export const fileStatusFilterSlice = createSlice({
    name: 'fileStatusFilter',
    initialState: {
        selectedFileStatus: null,
    },
    reducers: {
        setSelectedFileStatus: (state, action) => {
            state.selectedFileStatus = action.payload;
        },
    },
});


export const { setSelectedFileStatus } = fileStatusFilterSlice.actions;
