import React, {useContext, useState} from 'react';
import {
    Box,
    Typography,
    Button,
    Stack
} from '@mui/material';

import CustomTextField from '../../../components/forms/theme-elements/CustomTextField';
import {useSnackbar} from "notistack";
import {AxiosPost, AxiosPostWithoutState} from "../../../axios/AxiosPost";
import {authServiceApiUrl} from "../../../schema/Environment";
import {TokenContext} from "../../../schema/Contexts";

export default function AuthLogin ({ title }) {
    const { enqueueSnackbar } = useSnackbar();
    const [currentToken, setToken] = useContext(TokenContext);
    const [currentUsername, setCurrentUsername] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");

    const handleClick = () => {
        const user = { username: currentUsername, password: currentPassword};
        AxiosPostWithoutState(authServiceApiUrl + "/user/login", null, user, currentToken)
            .then((response) => {
                if(response.error){
                    throw new Error(response.error);
                }
                else {
                    setToken(response.data);
                }
            })
            .catch((error) => {
                if(error.response !== undefined)
                    enqueueSnackbar(error.response.data.error, { variant: 'error' });
                else
                    enqueueSnackbar(error.message, { variant: 'error' });
                return Promise.reject(error);
            });
    }

    return(
        <>
            {title ? (
                <Typography fontWeight="700" variant="h2" mb={1}>
                    {title}
                </Typography>
            ) : null}

            <Stack>
                <Box>
                    <Typography variant="uptitle2"
                                fontWeight={600} component="label" mb="5px">Если вы не имеете аккаунта, пожалуйста, обратитесь в IT
                        отдел</Typography>
                </Box>
                <Box mt="1rem">
                    <CustomTextField id="username" variant="outlined" fullWidth value={currentUsername} placeholder="Имя пользователя" onChange={e => setCurrentUsername(e.target.value)}/>
                </Box>
                <Box mt="1rem">
                    <CustomTextField id="password" type="password" variant="outlined" fullWidth value={currentPassword} placeholder="Пароль" onChange={e => setCurrentPassword(e.target.value)}/>
                </Box>
                <Box mt="1rem">
                    <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        fullWidth
                        onClick={handleClick}
                    >
                        Войти
                    </Button>
                </Box>
            </Stack>
        </>
    )
}
