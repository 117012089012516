import React, {useEffect, useState} from "react";
import { TextField, FormControl } from '@mui/material';

export default function StringField (props) {

    const [value, setValue] = useState('');

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    useEffect(() => {
        // Проверяем, предоставлен ли обработчик изменений через пропсы
        if (props.handleChange) {
            props.handleChange(props.id, value);
        }
    }, [value]); // Вызываем useEffect при изменении значения

    return (
        <FormControl fullWidth>
            <TextField
                id={props.id}
                label={props.label}
                variant="outlined"
                value={value}
                onChange={handleChange}
            />
        </FormControl>
    );
}
