import { createTheme } from "@mui/material/styles";
import typography from "./Typography";
import { shadows } from "./Shadows";

const baseTheme = createTheme({
  direction: 'ltr',
  palette: {
    primary: {
      main: '#5D87FF',
      light: '#ECF2FF',
      dark: '#4570EA',
      test: '#000000'
    },
    secondary: {
      main: '#49BEFF',
      light: '#E8F7FF',
      dark: '#23afdb',
    },
    success: {
      main: '#13DEB9',
      light: '#44b11e',
      dark: '#007b11',
      contrastText: '#ffffff',
    },
    info: {
      main: '#539BFF',
      light: '#00accd',
      dark: '#005ea5',
      contrastText: '#ffffff',
    },
    error: {
      main: '#FA896B',
      light: '#da614a',
      dark: '#ac1c00',
      contrastText: '#ffffff',
    },
    warning: {
      main: '#FFAE1F',
      light: '#f29d00',
      dark: '#c67700',
      contrastText: '#ffffff',
    },
    purple: {
      A50: '#EBF3FE',
      A100: '#6610f2',
      A200: '#557fb9',
    },
    grey: {
      100: '#F2F6FA',
      200: '#EAEFF4',
      300: '#d5d5d5',
      400: '#7C8FAC',
      500: '#5A6A85',
      600: '#2A3547',

    },
    text: {
      primary: '#2A3547',
      secondary: '#5A6A85',
      hint: '#595959',
    },
    action: {
      disabledBackground: 'rgba(73,82,88,0.12)',
      hoverOpacity: 0.02,
      hover: '#f6f9fc',
    },
    divider: '#e5eaef',
  },
  typography,
  shadows
},

);

export { baseTheme };
