import { createSlice } from '@reduxjs/toolkit';

// Определение начального состояния
const initialState = {
    isOpen: false,
    title: '',
    url: '',
};

// Создание slice
export const modalBillingProcessStatisticSlice = createSlice({
    name: 'modalFileUploadStatistic',
    initialState,
    reducers: {
        // Действие для открытия модального окна
        openBillingProcessStatisticModal: (state, action) => {
            state.isOpen = true;
            state.title = action.payload.title;
            state.url = action.payload.url;
        },
        // Действие для закрытия модального окна
        closeBillingProcessStatisticModal: (state) => {
            state.isOpen = false;
            state.title = '';
            state.url = '';
        },
    },
});

// Экспорт действий
export const { openBillingProcessStatisticModal, closeBillingProcessStatisticModal } = modalBillingProcessStatisticSlice.actions;

// Экспорт редьюсера
export default modalBillingProcessStatisticSlice.reducer;
