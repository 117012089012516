import {createSlice} from '@reduxjs/toolkit';
import {createSelector} from 'reselect';
import {BillingStatuses, BillingTypes} from "../BillingManagerView";


export const billingsSlice = createSlice({
    name: 'billings',
    initialState: [],
    reducers: {
        addBillingInfo: (state, action) => {
            const billingInfo = action.payload;

            billingInfo.bank = billingInfo.bank.toUpperCase();
            // Проверяем, существует ли уже процесс с таким processId в state
            const existingBillingInfoIndex = state.findIndex(p => p.id === billingInfo.id);
            if (existingBillingInfoIndex === -1) {
                // Если процесса с таким processId нет, добавляем новый объект processInfo в state
                state.push({
                    id: billingInfo.id,
                    bank: billingInfo.bank.toUpperCase(),
                    type: billingInfo.type,
                    rtmmIds: billingInfo.rtmmIds,
                    status: billingInfo.status,
                    created: billingInfo.created,
                    startDate: billingInfo.startDate,
                    endDate: billingInfo.endDate,
                    billingProcessList: billingInfo.fileProcessList
                });
            } else {
                // Если процесс с таким processId уже существует, обновляем его данные
                state[existingBillingInfoIndex] = { ...state[existingBillingInfoIndex], ...billingInfo, status: billingInfo.status };
            }
        },
        cleanBillingList: (state) => {
            return []; // Возвращаем пустой массив, очищая состояние
        },
        addBillingInfoList: (state, action) => {

            const billingList = action.payload; // Получаем массив объектов

            billingList.forEach(billingInfo => {
                billingInfo.bank = billingInfo.bank.toUpperCase();
            });

            billingList.forEach(billingInfo => {
                const existingFileInfoIndex = state.findIndex(j => j.id === billingInfo.id);
                if (existingFileInfoIndex === -1) {
                    // Если процесса с таким processId нет
                    state.push({
                        id: billingInfo.id,
                        bank: billingInfo.bank.toUpperCase(),
                        type: billingInfo.type,
                        rtmmIds: billingInfo.rtmmIds,
                        status: billingInfo.status,
                        created: billingInfo.created,
                        startDate: billingInfo.startDate,
                        endDate: billingInfo.endDate,
                        billingProcessList: billingInfo.billingProcessList
                    });
                } else {
                    // Если процесс с таким processId уже существует, обновляем его данные
                    state[existingFileInfoIndex] = { ...state[existingFileInfoIndex], ...billingInfo };
                }
            });

            // // Затем создаем новый массив для фильтрации, не мутируя текущий state
            // const now = new Date();
            // const oneWeekAgo = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
            // const newState = state.filter(job => {
            //     const fileCreatedDate = new Date(job.created);
            //     return fileCreatedDate > oneWeekAgo;
            // });
            //
            // // Очищаем текущее состояние
            // state.splice(0, state.length);
            //
            // // Заполняем текущее состояние отфильтрованными значениями
            // newState.forEach(billingInfo => state.push(billingInfo));
        },
    }
});

export const selectBillingList = state => state.billings;

// Выбор списка джобов по id
export const selectBillingById = createSelector(
    [selectBillingList, (_, id) => id],
    (billingList, id) => {
        return billingList.find(file => file.id === id)
    }
);

// Новый селектор для сортировки списка файлов по дате
export const selectSortedBillingsByDate = createSelector(
    [selectBillingList],
    (billingList) => {
        // debugger;
        return billingList.slice().sort((a, b) => new Date(b.created) - new Date(a.created))
    }
);

export function translateBillingType(value) {
    const billingType = BillingTypes.find(type => type.id === value);
    return billingType ? billingType.name : "Неизвестное значение"; // Возвращаем имя найденного типа или "Неизвестное значение", если ничего не найдено
}


export function translateBillingStatus(status) {
    const statusDetails = BillingStatuses.find(s => s.id === status);
    return statusDetails ? statusDetails.name : 'Неизвестный статус';
}


export const { addBillingInfo, addBillingInfoList, cleanBillingList } = billingsSlice.actions;
