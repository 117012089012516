import {createSlice} from '@reduxjs/toolkit';
import {createSelector} from 'reselect';


export const filesSlice = createSlice({
    name: 'files',
    initialState: [],
    reducers: {
        addFileInfo: (state, action) => {
            const fileInfo = action.payload;
            fileInfo.bank = fileInfo.bank.toUpperCase();
            // Проверяем, существует ли уже процесс с таким processId в state
            const existingFileInfoIndex = state.findIndex(p => p.id === fileInfo.id);
            if (existingFileInfoIndex === -1) {
                // Если процесса с таким processId нет, добавляем новый объект processInfo в state
                state.push({
                    id: fileInfo.id,
                    bank: fileInfo.bank.toUpperCase(),
                    name: fileInfo.name,
                    fileType: fileInfo.fileType,
                    status: fileInfo.status,
                    size: fileInfo.size,
                    created: fileInfo.created,
                    s3Path: fileInfo.s3Path,
                    countRows: fileInfo.countRows,
                    countErrors: fileInfo.countErrors,
                    fileProcessList: fileInfo.fileProcessList
                });
            } else {
                // Если процесс с таким processId уже существует, обновляем его данные
                state[existingFileInfoIndex] = { ...state[existingFileInfoIndex], ...fileInfo, status: fileInfo.status };
            }
        },
        cleanFileList: (state) => {
            return []; // Возвращаем пустой массив, очищая состояние
        },
        addFileInfoList: (state, action) => {

            const fileList = action.payload; // Получаем массив объектов

            fileList.forEach(fileInfo => {
                fileInfo.bank = fileInfo.bank.toUpperCase();
            });

            fileList.forEach(fileInfo => {
                const existingFileInfoIndex = state.findIndex(j => j.id === fileInfo.id);
                if (existingFileInfoIndex === -1) {
                    // Если процесса с таким processId нет
                    state.push({
                        id: fileInfo.id,
                        bank: fileInfo.bank.toUpperCase(),
                        name: fileInfo.name,
                        fileType: fileInfo.fileType,
                        status: fileInfo.status,
                        size: fileInfo.size,
                        created: fileInfo.created,
                        s3Path: fileInfo.s3Path,
                        countRows: fileInfo.countRows,
                        countErrors: fileInfo.countErrors,
                        fileProcessList: fileInfo.fileProcessList
                    });
                } else {
                    // Если процесс с таким processId уже существует, обновляем его данные
                    state[existingFileInfoIndex] = { ...state[existingFileInfoIndex], ...fileInfo };
                }
            });

            // Затем создаем новый массив для фильтрации, не мутируя текущий state
            // const now = new Date();
            // const oneWeekAgo = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
            // const newState = state;
            //     .filter(job => {
            //     const fileCreatedDate = new Date(job.created);
            //     return fileCreatedDate > oneWeekAgo;
            // });

            // Очищаем текущее состояние
            // state.splice(0, state.length);

            // Заполняем текущее состояние отфильтрованными значениями
            // newState.forEach(fileInfo => state.push(fileInfo));
        },
    }
});

export const selectFileList = state => state.files;

// Выбор списка джобов по id
export const selectFileById = createSelector(
    [selectFileList, (_, id) => id],
    (fileList, id) => {
        return fileList.find(file => file.id === id)
    }
);

// Новый селектор для сортировки списка файлов по дате
export const selectSortedFilesByDate = createSelector(
    [selectFileList],
    (files) => {
        // debugger;
        return files.slice().sort((a, b) => new Date(b.created) - new Date(a.created))
    }
);

function sortByDate(dateString) {
    const date = new Date(dateString);

}


// Функция для сортировки по subId
function sortBySubId(a, b) {
    const subIdA = parseInt(a.split('-')[1], 10);
    const subIdB = parseInt(b.split('-')[1], 10);

    // Проверяем, удалось ли успешно разобрать числа
    if (!isNaN(subIdA) && !isNaN(subIdB)) {
        return subIdA - subIdB;
    } else {
        // Если не удалось разобрать числа, оставляем элементы в исходном порядке
        return 0;
    }
}

export const FileTypes = [
    { name: "Активации", id: "ACTIVATIONS", color: 'blue'},
    { name: "Клиенты", id: "CLIENTS", color: 'green'},
    { name: "Транзакции", id: "TRANSACTIONS", color: 'deepskyblue'},
    { name: "Коммуникации", id: "COMMUNICATIONS", color: 'purple'},
    { name: "Маппинг клиентов", id: "CLIENT_ID_MAPPING", color: 'lightcoral'},
    { name: "Окончательные транзакции", id: "TRANSACTIONS_FINAL", color: '#006b8f'},
    { name: "Заходы", id: "RESPONSES", color: '#e39300'},
    { name: "Нерегулярные", id: "NONE_REGULAR", color: 'grey'},
    { name: "Неизвестное значение", id: "DEFAULT", color: 'grey'},
];

export const FileStatuses = [
    { name: "Ожидаем получения", id: "DETECTED", color: 'rgb(0, 0, 255)' },
    { name: "Готов к транспортировке в хранилище", id: "WAITING_FOR_TRANSPORT_TO_STORAGE", color: 'rgb(230,157,0)' },
    { name: "Ожидает расшифровки", id: "WAITING_DECRYPT", color: 'rgb(230,157,0)' },
    { name: "Ожидает упаковки перед загрузкой", id: "WAITING_ZIP_BEFORE_UPLOAD", color: 'rgb(230,157,0)' },
    { name: "Выполняется сжатие файла", id: "EXECUTE_ZIP", color: 'rgb(0, 0, 255)' },
    { name: "Упакован", id: "ZIPPED", color: 'rgb(0, 0, 255)' },
    { name: "Выполняется транспортировка в хранилище", id: "EXECUTE_UPLOAD_TO_STORAGE", color: 'rgb(0, 0, 255)' },
    { name: "Загружен в хранилище", id: "UPLOADED_TO_STORAGE", color: 'rgb(0, 0, 255)' },
    { name: "Ожидает загрузки в систему", id: "WAITING_FOR_UPLOAD_TO_SYSTEM", color: 'rgb(230,157,0)' },
    { name: "Загружается в систему", id: "EXECUTE_UPLOAD_TO_SYSTEM", color: 'rgb(0, 0, 255)' },
    { name: "Загружен", id: "UPLOADED_TO_SYSTEM", color: 'rgb(51,135,0)' },
    { name: "Ошибка на sftp сервере", id: "ERROR_WITH_PREPARE_TRANSFER_FILE", color: 'rgb(163,0,0)' },
    { name: "Ошибка при загрузке файла", id: "ERROR", color: 'rgb(149,2,2)' },
    { name: "Критическое количество ошибок", id: "CRITICAL_ERROR_COUNTS", color: 'rgb(149,2,2)' },
    { name: "Загружен c ошибками", id: "UPLOADED_WITH_ERRORS", color: 'rgb(230,157,0)' },
    { name: "Нехватило памяти", id: "NEED_MORE_MEMORY", color: 'rgb(149,2,2)' },
    { name: "Выполняется остановка", id: "TO_STOP", color: 'rgb(198,119,0)' },
    { name: "Остановлен", id: "STOPPED", color: 'rgb(206,125,14)' }
];


export function translateFileType(value) {
    const fileType = FileTypes.find(type => type.id === value);
    return fileType ? fileType.name : "Неизвестное значение"; // Возвращаем имя найденного типа или "Неизвестное значение", если ничего не найдено
}


export function translateFileStatus(status) {
    const statusDetails = FileStatuses.find(s => s.id === status);
    return statusDetails ? statusDetails.name : 'Неизвестный статус';
}

export const {addFileInfo, addFileInfoList, cleanFileList } = filesSlice.actions;
