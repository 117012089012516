export const authServiceApiUrl = "https://sweetcard.ru/auth";
// export const processServiceApiUrl = "http://localhost:8080";
export const processServiceApiUrl = "https://sweetcard.ru/process-service";
export const jobServiceApiUrl = "https://sweetcard.ru/job-service";
export const fileServiceApiUrl = "https://sweetcard.ru/file-manager-service";
export const billingServiceApiUrl = "https://sweetcard.ru/billing-manager-service";
// export const fileServiceApiUrl = "http://localhost:8080";
// export const billingServiceApiUrl = "http://localhost:8080";
// export const jobServiceApiUrl = "http://localhost:8080";

export const admitadApiRSB = "https://service.sweetcard.ru/admitad/rsb/"
export const admitadApiCEB = "https://service.sweetcard.ru/admitad/ceb/"
export const admitadApiGPB = "https://service.sweetcard.ru/admitad/gpb/"

export const frameDataApiUrl = "https://service.sweetcard.ru/version-api";

export const serviceName = "admin-panel";