import { createSlice } from '@reduxjs/toolkit';

export const fileTypeFilterSlice = createSlice({
    name: 'fileTypeFilter',
    initialState: {
        selectedFileType: null,
    },
    reducers: {
        setSelectedFileType: (state, action) => {
            state.selectedFileType = action.payload;
        },
    },
});


export const { setSelectedFileType } = fileTypeFilterSlice.actions;
