import { createSlice } from '@reduxjs/toolkit';

// Определение начального состояния
const initialState = {
    isOpen: false,
    title: '',
    url: '',
};

// Создание slice
export const modalFileUploadStatisticSlice = createSlice({
    name: 'modalFileUploadStatistic',
    initialState,
    reducers: {
        // Действие для открытия модального окна
        openFileUploadStatisticModal: (state, action) => {
            state.isOpen = true;
            state.title = action.payload.title;
            state.url = action.payload.url;
        },
        // Действие для закрытия модального окна
        closeFileUploadStatisticModal: (state) => {
            state.isOpen = false;
            state.title = '';
            state.url = '';
        },
    },
});

// Экспорт действий
export const { openFileUploadStatisticModal, closeFileUploadStatisticModal } = modalFileUploadStatisticSlice.actions;

// Экспорт редьюсера
export default modalFileUploadStatisticSlice.reducer;
