import React, {useState} from 'react';
import DashboardCard from "../../../components/shared/DashboardCard";
import Grid from "@mui/material/Grid";
import NavItem from "../sidebar/NavItem/NavItem";
import {Badge} from "@mui/material";

const gridSettings = {
    EXTRA_SMALL: { md: 1, lg: 1 },
    SMALL: { md: 2, lg: 2 },
    MIDDLE: { md: 3, lg: 3 },
    BIG: { md: 4, lg: 4 },
    LARGE: { md: 6, lg: 6 },
    DEFAULT: { md: 4, lg: 4 }
};

const TypeSwitchBar = ({items, title, selectedId, valueSetter, gridSize = 'DEFAULT'}) => {

    const settings = gridSettings[gridSize];

    const handleChangeGroup = (id) => {
        // setValue(id);
        if(selectedId !== id) {
            if (valueSetter !== undefined) {
                valueSetter(id);
            }
        }
        else{
            if (valueSetter !== undefined) {
                valueSetter(undefined);
            }
        }
    };

    if(items) {
        return (
            <DashboardCard title={title}>
                <Grid container spacing={1}>
                    {items.map((item, index) => {
                        return (
                            <Grid item key={index} xs={12} sm={6} md={settings.md} lg={settings.lg}>
                                {/*<Badge badgeContent={1}  key={item.id} color="primary" anchorOrigin={{*/}
                                {/*    vertical: 'top',*/}
                                {/*    horizontal: 'right',*/}
                                {/*}}>*/}
                                    <NavItem item={item} key={item.id} pathDirect={selectedId}
                                             onClick={() => handleChangeGroup(item.id)}/>
                                {/*</Badge>*/}
                            </Grid>
                        );
                    })}
                </Grid>
            </DashboardCard>
        );
    }
};

export default TypeSwitchBar;
