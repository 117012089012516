import React, {useEffect, useState} from "react";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";

export default function MultiSelectField (props) {

    const [selectedValues, setSelectedValues] = useState([]);

    const handleChange = (event) => {
        setSelectedValues(event.target.value);
    };

    useEffect(() => {
        if(props.handleChange){
            props.handleChange(props.id, selectedValues);
        }
    }, [selectedValues]);

    const sortedValues = props.itemList.sort((a, b) => {
        if (a.value < b.value) {
            return -1;
        } else if (a.value > b.value) {
            return 1;
        } else {
            return 0;
        }
    });

    const renderSelectedValues = () => {
        if (selectedValues.length === 0) {
            return 'Select...';
        } else {
            return selectedValues
                .map((value) => sortedValues.find((item) => item.key === value).value)
                .join(', ');
        }
    };

    const menuItemStyle = (selected) => ({
        color: selected ? '#5D87FF' : 'inherit',
        fontWeight: selected ? 'bold' : 'normal'
    });


    return (
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Значение</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedValues}
                multiple
                label="Age"
                onChange={handleChange}
                renderValue={renderSelectedValues}
            >

                {sortedValues.map((item, index) => {
                    return (
                        <MenuItem key={item.key} value={item.key} sx={menuItemStyle(selectedValues.includes(item.key))}>{item.value}</MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    )
}
