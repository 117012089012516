import { createSlice } from '@reduxjs/toolkit';

export const websocketSlice = createSlice({
    name: 'websocket',
    initialState: {
        connections: {}  // Сохраняем статусы и подписки
    },
    reducers: {
        setConnectionStatus(state, action) {
            const { urlKey, isConnected } = action.payload;
            state.connections[urlKey] = { ...state.connections[urlKey], isConnected };
        },
        addSubscription(state, action) {
            const { urlKey, topic } = action.payload;
            const subscriptions = state.connections[urlKey]?.subscriptions || {};
            subscriptions[topic] = true;  // Просто маркируем подписку как активную
            state.connections[urlKey] = { ...state.connections[urlKey], subscriptions };
        },
        removeSubscription(state, action) {
            // debugger;
            const { urlKey, topic } = action.payload;
            if (state.connections[urlKey]?.subscriptions[topic]) {
                delete state.connections[urlKey].subscriptions[topic];
            }
        }
    }
});

export const { setConnectionStatus, addSubscription, removeSubscription } = websocketSlice.actions;