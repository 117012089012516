import { createSlice } from '@reduxjs/toolkit';

export const bankFilterSlice = createSlice({
    name: 'bankFilter',
    initialState: {
        selectedTypeId: null,
    },
    reducers: {
        setSelectedTypeId: (state, action) => {
            state.selectedTypeId = action.payload;
        },
    },
});


export const Banks = [
    { name: "Газпром", id: "GPB", color: 'rgb(0,94,165)', old_id: 'gazprom' },
    { name: "Юникредит", id: "UCB", color: 'rgb(172,28,0)', old_id: 'unicredit' },
    { name: "Хоум кредит", id: "HCB", color: 'rgb(163,0,0)', old_id: 'homecredit' },
    { name: "Росбанк", id: "RSB", color: 'rgb(149,2,2)', old_id: 'rosbank' },
    { name: "Альфа", id: "ALF", color: 'rgb(149,2,2)', old_id: 'alphabank' },
    { name: "БКС Банк", id: "BKS", color: 'rgb(0,94,165)', old_id: 'bks' },
    { name: "Кредит Европа", id: "CEB", color: 'rgb(23,162,184)', old_id: 'cebank' },
    { name: "МКБ", id: "MKB", color: 'rgb(255,193,7)', old_id: 'mkb' },
    { name: "МТС", id: "MTS", color: 'rgb(149,2,2)', old_id: 'mts' },
    { name: "Русский Стандарт", id: "RST", color: 'rgb(108,108,108)', old_id: 'rst' },
    { name: "Санкт Петербург", id: "SPB", color: 'rgb(0,94,165)', old_id: 'spb' },
    { name: "Открытие", id: "OPN", color: 'rgb(0,172,205)', old_id: 'opn' },
    { name: "Райфайзен", id: "RFZ", color: 'rgb(196,188,27)', old_id: 'rfz' },
    { name: "Тинькоф", id: "TNK", color: 'rgb(196,188,27)', old_id: 'tinkoff' },
    { name: "Cashback Force", id: "CBF", color: 'rgb(108,108,108)', old_id: 'cbf' },
    { name: "АТБанк", id: "ATB", color: 'rgb(206,125,14)', old_id: 'atb' },
    { name: "Цупис", id: "CUP", color: 'rgb(40,167,69)', old_id: 'cup' },
    { name: "ОТП Банк", id: "OTP", color: 'rgb(40,167,69)', old_id: 'otp' }
];

export function getColorBankByKeyword(keyword) {
    if (keyword) {
        const bank = Banks.find(status => keyword.includes(status.id));
        return bank ? bank.color : 'grey'; // Возвращаем цвет найденного статуса или серый, если ничего не найдено
    }
    return 'grey'; // Возвращаем серый цвет, если ключевое слово отсутствует
}

export function getNameBankByKeyword(keyword) {
    if (keyword) {
        const bankStatus = Banks.find(status => keyword.includes(status.id));
        return bankStatus ? bankStatus.name : 'Неизвестный банк'; // Возвращаем имя найденного статуса или 'Unknown', если ничего не найдено
    }
    return 'Неизвестный банк'; // Возвращаем 'Unknown', если ключевое слово отсутствует
}

export const { setSelectedTypeId } = bankFilterSlice.actions;
