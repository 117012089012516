import { createSlice } from '@reduxjs/toolkit';

export const billingStatusFilterSlice = createSlice({
    name: 'billingStatusFilter',
    initialState: {
        selectedBillingStatus: null,
    },
    reducers: {
        setSelectedBillingStatus: (state, action) => {
            state.selectedBillingStatus = action.payload;
        },
    },
});


export const { setSelectedBillingStatus } = billingStatusFilterSlice.actions;
