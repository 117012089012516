import React, {useEffect, useMemo, useState} from 'react';
import {
    Badge,
    Box,
    Button,
    Dialog, DialogContent, DialogTitle, Grid,
    IconButton,
    Paper,
    Skeleton, Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Typography
} from '@mui/material';
import PageContainer from '../../components/container/PageContainer';
import DashboardCard from '../../components/shared/DashboardCard';
import TypeSwitchBar from "../../layouts/full/TypeSwitchBar/TypeSwitchBar";
import {IconLayoutDashboard} from "@tabler/icons";
import {useSnackbar} from "notistack";
import {AxiosGet, AxiosGetWithoutState} from "../../axios/AxiosGet";
import {
    admitadApiCEB, admitadApiGPB,
    admitadApiRSB,
    billingServiceApiUrl,
    fileServiceApiUrl,
    jobServiceApiUrl
} from "../../schema/Environment";

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import JobLauncherBox, {ParamBox} from "./JobLauncherBox";
import {useDispatch, useSelector} from "react-redux";
import {useTheme} from "@mui/material/styles";
import UseToken from "../../auth/UseToken";
import CustomDatePicker from "../../components/inputs/CustomDatePicker";


const admitadBanks = [
    {
        id: "RSB",
        title: 'Росбанк',
        icon: IconLayoutDashboard,
        url: admitadApiRSB
    },
    {
        id: "CEB",
        title: 'Кредит Европа Банк',
        icon: IconLayoutDashboard,
        url: admitadApiCEB
    },
    {
        id: "GPB",
        title: 'Газпром',
        icon: IconLayoutDashboard,
        url: admitadApiGPB
    }
];

const findUrlById = (id) => {
    const bank = admitadBanks.find(bank => bank.id === id);
    return bank ? bank.url : null;
};



const admitadCashbacks = [
    {
        id: 1,
        campaignId: "1",
        campaignName: "AliExpress",
        cashback: 15.75,
        typeCashback: "Белый кэшбэк"
    },
    {
        id: 2,
        campaignId: "1",
        campaignName: "AliExpress",
        cashback: 20.00,
        typeCashback: "percent"
    },
    {
        id: 3,
        campaignId: "1",
        campaignName: "AliExpress",
        cashback: 10.50,
        typeCashback: "percent"
    },
    {
        id: 4,
        campaignId: "1",
        campaignName: "AliExpress",
        cashback: 12.25,
        typeCashback: "percent"
    },
    {
        id: 5,
        campaignId: "1",
        campaignName: "AliExpress",
        cashback: 18.00,
        typeCashback: "percent"
    }
];


export const AdmitadActionStatuses = [
    { name: "Не обработан", id: "NOT_PROCESSED", color: 'rgb(196,188,27)', count: 10 },
    { name: "Кэшбэк не найден", id: "NOT_FOUND_CASHBACK", color: 'rgb(149,2,2)', count: 20 },
    { name: "Нет активной карты", id: "NOT_FOUND_ACTIVE_CARDS", color: 'rgb(163,0,0)', count: 30 },
    { name: "Нет id клиента(subid3)", id: "SUBID3_IN_ADMITAD_EQUALS_NULL", color: 'rgb(172,28,0)', count: 40 },
    { name: "Подготовлено к выплате", id: "PREPARED_FOR_PAYMENT", color: 'rgb(0, 0, 255)', count: 50 },
    { name: "В реестре", id: "PROCESSED", color: 'rgb(51,135,0)', count: 60 }
];

const getStatusNameById = (id) => {
    const status = AdmitadActionStatuses.find(status => status.id === id);
    return status ? status.name : null;
};


// тут нам нужны:
// статистика биллингов
// запуск выставления реестра
// статистика по строкам адмитада
// список коммиссий и их изменение

function AdmitadView () {
    const { enqueueSnackbar } = useSnackbar();
    const {currentToken, setToken} = UseToken();
    const dispatch = useDispatch();
    const [loaded, setLoaded] = useState(false);
    const [selectedBank, setSelectedBank] = useState(null);


    return (
        <>
            {/*<DashboardCard title="Внимание">*/}
            {/*    <Box sx={{marginBottom: "1rem"}}>*/}
            {/*        <Typography variant="uptitleBold2" gutterBottom>*/}
            {/*            Данная страница находится в разработке*/}
            {/*        </Typography>*/}
            {/*    </Box>*/}
            {/*</DashboardCard>*/}
            <PageContainer title="Sample Page" description="this is Sample page">
                <TypeSwitchBar title={'Банк'} items={admitadBanks} valueSetter={setSelectedBank} selectedId={selectedBank}/>
            </PageContainer>

            {selectedBank ?
                <AdmitadDataView selectedBank={selectedBank}/>
                : <></>
            }
        </>
    );
}

function AdmitadDataView ({selectedBank}){
    return(
        <>
            {/*<AdmitadCashbackView/>*/}
            <AdmitadBillingLogsView selectedBank={selectedBank}/>
            {/*<MakeRegistryView/>*/}
            <AdmitadActionView selectedBank={selectedBank}/>
        </>
    )
}


function AdmitadBillingLogsView({selectedBank}) {
    const theme = useTheme();
    const url = findUrlById(selectedBank) + "admitad_billing_log";
    const { enqueueSnackbar } = useSnackbar();
    const { loaded, data, error } = AxiosGet(url, "GET", null, enqueueSnackbar);

    useEffect(() => {
    }, [selectedBank]);

    if(loaded === false && !error){
        return (
            <PageContainer title="Sample Page" description="this is Sample page">
                <DashboardCard>
                    <Skeleton />
                </DashboardCard>
            </PageContainer>
        )
    }
    else if (loaded === true && !error) {
        // debugger;
        return (
            <>
                <Box sx={{marginBottom: '1rem'}}>
                    <Typography variant="uptitleBold1" gutterBottom>
                        История биллингов
                    </Typography>
                </Box>
                {/*<DashboardCard title="История биллингов">*/}
                <TableContainer component={Paper}
                                style={{paddingBottom: "1rem", marginBottom: "1rem", maxWidth: "100%"}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align={"center"} sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.light,
                                    fontWeight: 800
                                }}>Кампания</TableCell>
                                <TableCell align={"center"} sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.light,
                                    fontWeight: 800,
                                    maxWidth: "15rem"
                                }}>Количество транзакций</TableCell>
                                <TableCell align={"center"} sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.light,
                                    fontWeight: 800
                                }}>Сумма покупок</TableCell>
                                <TableCell align={"center"} sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.light,
                                    fontWeight: 800
                                }}>Сумма кешбека</TableCell>
                                <TableCell align={"center"} sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.light,
                                    fontWeight: 800
                                }}>Сумма выплат SC</TableCell>
                                <TableCell align={"center"} sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.light,
                                    fontWeight: 800
                                }}>Дата выставления</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((log, index) => (
                                <AdmitadBillingLogRow id={log.id} index={index} log={log}/>
                            ))}
                        </TableBody>

                    </Table>
                </TableContainer>
                {/*</DashboardCard>*/}
            </>
        );
    }
}

function AdmitadBillingLogRow({id,index, log}) {
    return(
        <React.Fragment key={id}>
            <TableRow key={index}>
                <TableCell align="center"><Typography variant="uptitleBold3">{log.campaignId}</Typography></TableCell>
                <TableCell align="center"><Typography variant="uptitleBold3">{log.transactionCount}</Typography></TableCell>
                <TableCell align="center"><Typography variant="uptitleBold3">{log.sumOrderPrice}</Typography></TableCell>
                <TableCell align="center"><Typography variant="uptitleBold3">{log.cashbackSum}</Typography></TableCell>
                <TableCell align="center"><Typography variant="uptitleBold3">{log.sumPaymentCampaign}</Typography></TableCell>
                <TableCell align="center"><Typography variant="uptitleBold3">{formatDate(log.created)}</Typography></TableCell>
            </TableRow>
        </React.Fragment>
    )
}


function AdmitadActionView({selectedBank}) {
    const theme = useTheme();
    const url = findUrlById(selectedBank);
    const { enqueueSnackbar } = useSnackbar();
    const {currentToken, setToken} = UseToken();
    const [queryParams, setQueryParams] = useState({
        startDate: new Date(),
        endDate: new Date(),
    });
    const [admitadActions, setAdmitadActions] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(null);

    const handleStatusClick = (id) => {
        setSelectedStatus(id);
    };

    useEffect(() => {
        setAdmitadActions([]);
        setSelectedStatus(null);
    }, [selectedBank]);


    function handleParamChange(paramId, newValue) {
        setQueryParams((prevParams) => ({
            ...prevParams,
            [paramId]: newValue,
        }));
    }

    const handleFetchTransactions = () => {
        AxiosGetWithoutState(url + "admitad_action", "GET",{"startDate": queryParams.startDate, "endDate": queryParams.endDate},  currentToken).then(({loaded, error, data}) => {
            if(error){
                enqueueSnackbar(error, {variant: "error"});
            }
            else {
                setAdmitadActions(data)
            }
        }).catch(error => {
            console.log(error);
            enqueueSnackbar("Непредвиденная ошибка при получении списка транзакций из адмитад", {variant: "error"});
        });
    };

    const handleProcessTransactions = () => {
        AxiosGetWithoutState(url + "admitad/registry", "GET",{"startDate": queryParams.startDate, "endDate": queryParams.endDate},  currentToken).then(({loaded, error, data}) => {
            if(error){
                enqueueSnackbar(error, {variant: "error"});
            }
            else {
                enqueueSnackbar("Транзакции успешно выставлены в реестр", {variant: "info"});
                handleFetchTransactions();
            }
        }).catch(error => {
            console.log(error);
            enqueueSnackbar("Непредвиденная ошибка при выставлении транзакций", {variant: "error"});
        });
    };

    const descriptions = {
        startDate: 'Начальная дата',
        endDate: 'Конечная дата',
    };


    return (
        <>
            <DashboardCard title="">
                <Box sx={{marginBottom: "1rem"}}>
                    <Typography variant="uptitleBold1" gutterBottom>
                        Выберите даты для получения транзакций по адмитад
                    </Typography>
                </Box>

                <Grid container spacing={2}>
                    {Object.entries(queryParams).map(([key, value], index) => (
                        <Grid key={index} item xs={12} sm={6} md={4} lg={4}>
                            <Box >
                                <Typography variant="uptitleBold2" gutterBottom>
                                    {descriptions[key]}
                                </Typography>
                            </Box>
                            <CustomDatePicker id={key} value={value} handleChange={handleParamChange} />
                        </Grid>
                    ))}

                    <Grid key={-1} item xs={12} sm={6} md={4} lg={4} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }} >
                        <Box sx={{ display: 'flex', alignItems: 'bottom', justifyContent: 'flex-end', marginTop: '1rem' }}>
                            <Button variant="contained" color="primary"  sx={{ textTransform: 'none', height: "3rem" }} onClick={handleFetchTransactions}>
                                Получить транзакции
                            </Button>
                        </Box>
                    </Grid>
                </Grid>

                {admitadActions.length > 0 ?
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '1rem' }}>
                        <Box>
                            <Typography variant="uptitleBold1" color="text.secondary">
                                Транзакции со статусом "Подготовлено к выплате" можно отправить в реестр
                            </Typography>
                        </Box>
                        <Button variant="contained" color="primary"  sx={{ textTransform: 'none' }} onClick={handleProcessTransactions}>
                            Отправить транзакции в реестр
                        </Button>
                    </Box>
                : <></>}
            </DashboardCard>

            <AdmitadTransactionStatuses admitadActions={admitadActions} selectedStatus={selectedStatus} handleStatusClick={handleStatusClick}/>
            <AdmitadTransactions admitadActions={admitadActions}  selectedStatus={selectedStatus}/>
        </>
    );
}

function AdmitadTransactions({admitadActions, selectedStatus}) {
    const theme = useTheme();

    let transactions = admitadActions;
    if(selectedStatus !== null)
        transactions = transactions.filter(transaction => transaction.statusBilling === selectedStatus);

    if(admitadActions.length > 0)
        return (
            <TableContainer component={Paper} style={{paddingBottom: "1rem", marginBottom: "1rem", maxWidth: "100%"}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align={"center"} sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                fontWeight: 800
                            }}>Ритейлер</TableCell>
                            <TableCell align={"center"} sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                fontWeight: 800,
                                maxWidth: "15rem"
                            }}>Дата транзакции</TableCell>
                            <TableCell align={"center"} sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                fontWeight: 800
                            }}>Сумма</TableCell>
                            <TableCell align={"center"} sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                fontWeight: 800
                            }}>Кешбек</TableCell>
                            <TableCell align={"center"} sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                fontWeight: 800
                            }}>Client ID</TableCell>
                            <TableCell align={"center"} sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                fontWeight: 800
                            }}>Loyalty Id</TableCell>
                            <TableCell align={"center"} sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.light,
                                fontWeight: 800
                            }}>Статус</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {transactions.map((row, index) => (
                            <AdmitadActionRow  id={row.id} index={index} row={row}/>
                        ))}
                    </TableBody>

                </Table>
            </TableContainer>
        );
    else return <></>

}

function AdmitadTransactionStatuses({admitadActions, selectedStatus, handleStatusClick}) {

    const theme = useTheme();
    const getStatusCounts = (actions) => {
        const counts = {};
        actions.forEach(action => {
            if (counts[action.statusBilling]) {
                counts[action.statusBilling]++;
            } else {
                counts[action.statusBilling] = 1;
            }
        });
        return counts;
    };

    const statusCounts = getStatusCounts(admitadActions);

    if(admitadActions.length > 0)
        return (
        <>
            <Box sx={{marginBottom: '1rem'}}>
                <Typography variant="uptitleBold1" gutterBottom>
                    Статусы транзакций
                </Typography>
            </Box>

            <Box sx={{ marginBottom: '1rem'}}>
                <Grid container spacing={3}>
                    {AdmitadActionStatuses.map((status, index) => (
                        <Grid item xs={12} sm={4} md={2} key={index}>
                            <Box sx={{position: 'relative', height: '100%'}}>
                                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', cursor: 'pointer',
                                    backgroundColor: selectedStatus === status.id ? '#5d87ff' : '#ffffff' }} onClick={() => handleStatusClick(status.id)}>
                                    <Grid container spacing={2} direction="column" alignItems="center">
                                        <Grid item>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <Typography variant="uptitleBoldExtraLarge" textAlign="center" component="div" color={selectedStatus === status.id ? theme.palette.success.contrastText : status.color}>
                                                    {statusCounts[status.id] || 0}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <Typography variant="uptitleBold2" color={selectedStatus === status.id ? theme.palette.success.contrastText : theme.palette.grey[400]} textAlign="center">
                                                    {status.name}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    )
    else return <></>

}

function AdmitadActionRow({id,index, row}) {
    return(
        <React.Fragment key={id}>
            <TableRow key={index}>
                <TableCell align="center"><Typography variant="uptitleBold3">{row.advcampaignName}</Typography></TableCell>
                <TableCell align="center"><Typography variant="uptitleBold3">{formatDate(row.actionTime)}</Typography></TableCell>
                <TableCell align="center"><Typography variant="uptitleBold3">{row.updatedSum}</Typography></TableCell>
                <TableCell align="center"><Typography variant="uptitleBold3">{row.cashbackSum}</Typography></TableCell>
                <TableCell align="center"><Typography variant="uptitleBold3">{row.clientId}</Typography></TableCell>
                <TableCell align="center"><Typography variant="uptitleBold3">{row.subID3}</Typography></TableCell>
                <TableCell align="center"><Typography variant="uptitleBold3">{getStatusNameById(row.statusBilling)}</Typography></TableCell>
            </TableRow>
        </React.Fragment>
    )
}

//<DashboardCard title="Параметры">

function AdmitadCashbackView() {
    const theme = useTheme();
    const dispatch = useDispatch();

    return (
        <>
            <DashboardCard title="Настройки кешбеков для биллинга адмитад">
                <TableContainer component={Paper} style={{paddingBottom: "1rem", marginBottom: "1rem", marginTop: "1rem", maxWidth: "100%"}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align={"center"} sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.light,
                                    fontWeight: 800
                                }}>Id оффера</TableCell>
                                <TableCell align={"center"} sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.light,
                                    fontWeight: 800,
                                    maxWidth: "15rem"
                                }}>Ритейлер</TableCell>
                                <TableCell align={"center"} sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.light,
                                    fontWeight: 800
                                }}>Кешбек</TableCell>
                                <TableCell align={"center"} sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.light,
                                    fontWeight: 800
                                }}>Тип кешбека</TableCell>
                                <TableCell align={"center"} sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.light,
                                    fontWeight: 800
                                }}>Изменить</TableCell>
                                <TableCell align={"center"} sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.light,
                                    fontWeight: 800
                                }}>Удалить</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {admitadCashbacks.map((row, index) => (
                                <AdmitadCashbackRow  id={row.id} index={index} row={row}/>
                            ))}
                        </TableBody>

                    </Table>
                </TableContainer>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant="contained" color="primary" onClick={() => {}}>
                        Добавить кешбек
                    </Button>
                </Box>
            </DashboardCard>

        </>
    );
}

function AdmitadCashbackRow({id,index, row}) {
    return(
        <React.Fragment key={id}>
            <TableRow key={index}>
                <TableCell align="center"><Typography variant="uptitleBold3">{row.campaignId}</Typography></TableCell>
                <TableCell align="center"><Typography variant="uptitleBold3">{row.campaignName}</Typography></TableCell>
                <TableCell align="center"><Typography variant="uptitleBold3">{row.cashback}</Typography></TableCell>
                <TableCell align="center"><Typography variant="uptitleBold3">{row.typeCashback}</Typography></TableCell>
                <TableCell align="center"><Typography variant="uptitleBold3"><EditIcon onClick={() => {}}
                                                                                                style={{cursor: 'pointer'}}/></Typography></TableCell>
                <TableCell align="center"><Typography variant="uptitleBold3"><DeleteIcon  onClick={() => {}}
                                                                                                 style={{cursor: 'pointer'}}/></Typography></TableCell>
            </TableRow>
        </React.Fragment>
    )
}

function MakeRegistryView(){

    const AdmitadRegistryJob = {
        id: 0,
        description: "Введите даты для транзакций и нажмите кнопку \"Запустить\"\n" +
            "                После этого транзакции Admitad со статусом PREPARED_FOR_PAYMENT будут добавлены в реестр",
        jobParams: [
            {
                jobId: 0,
                name: "START_DATE",
                description: "Дата старта",
                jobParamType: "DATE",
                required: true,
                jobParamValues: [
                ]
            },
            {
                jobId: 0,
                name: "END_DATE",
                description: "Дата окончания",
                jobParamType: "DATE",
                required: true,
                jobParamValues: [
                ]
            }
        ]
    };
    return(
        <JobLauncherBox job={AdmitadRegistryJob} url={billingServiceApiUrl + "/billing/create/new/listByParams"}/>
    )
}

function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export default AdmitadView;
