import React, {useEffect, useState} from "react";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

export default function CustomDatePicker (props) {
    const [value, setValue] = useState(null);

    const handleChange = (value) => {
        setValue(value);
    };

    useEffect(() => {
        if(props.handleChange){
            const date = dayjs(value);
            const formattedDate = date.format('YYYY-MM-DD');
            props.handleChange(props.id, formattedDate);
        }
    }, [value]);

    return(
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                value={value}
                onChange={(newValue) => handleChange(newValue)}
                format="YYYY-MM-DD"
                sx={{ width: '100%' }}
             views={['year', 'month', 'day']}/>
        </LocalizationProvider>
    )
}
